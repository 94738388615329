import "./service-card.scss";

import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren } from "react";

import { ServiceResume } from "../ServicesResume";

type PropsServiceCard = PropsWithChildren<{ resume: ServiceResume }>;

const ServiceCard: React.FC<PropsServiceCard> = (props: PropsServiceCard) => {
  return (
    <div className={"m-service-card"}>
      <Link to={`${props.resume.slug.current}`}>
        <SanityImage
          {...props.resume.photo.image}
          alt={props.resume.photo.alt}
          className={"a-service-card__photo"}
          style={{ maxWidth: "536px" }}
          width={536}
          height={283}
        />
        <p className={"m-service-card__title"}>{props.resume.name}</p>
      </Link>
    </div>
  );
};

export default ServiceCard;
