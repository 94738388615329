import "../../styles/layouts/our-products.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import BenefitsAndInfo, { Benefits, Info } from "../../components/BenefitsAndInfo/BenefitsAndInfo";
import GalleryProducts from "../../components/GalleryProducts/GalleryProducts";
import HeroService from "../../components/Hero/HeroService/HeroService";
import Layout from "../../components/Layout/layout";
import { SEO } from "../../components/SEO/SEO";
import ServiceMarkupSchema from "../../components/SEO/ServiceMarkupSchema";
import ServicesResume, { ServiceResume } from "../../components/ServicesResumen/ServicesResume";
import SliderProducts from "../../components/SliderProducts/SliderProducts";
import SubscribeNewsletter from "../../components/SubscribeNewsletter/SubscribeNewsletter";
import { Problem } from "../../components/WeKnowYourProblems/ProblemCard/ProblemCard";
import WeKnowYourProblems from "../../components/WeKnowYourProblems/WeKnowYourProblems";
import WeTalk from "../../components/WeTalk/WeTalk";
import { Photo, Slug } from "../../types/shared";

const Products = ({ data }: PageProps<Queries.ProductsQuery>) => {
  const serviceResumeList: ServiceResume[] = [];

  data.allSanityTemplateFoodServicesServices.nodes.forEach((service) => {
    if (!service.hero?.title || !service.seo?.thumbnail) {
      return;
    }
    serviceResumeList.push({
      name: service.hero.title,
      photo: { image: service.seo.thumbnail, alt: `Foto de portada de ${service.hero.title}` } as unknown as Photo,
      slug: { current: `/distribucion-comercial/${service.slug?.current}/` } as Slug,
    });
  });
  if (data.sanityPageApp?.seo?.thumbnail) {
    const serviceApp: ServiceResume = {
      slug: { current: `/app-usicom/` },
      name: `Usicom`,
      photo: { image: data.sanityPageApp?.seo?.thumbnail, alt: `Foto de portada de Usicom` } as unknown as Photo,
    };
    serviceResumeList.push(serviceApp);
  }

  return (
    <Layout>
      <div className="l-our-products">
        <HeroService
          title={data.sanityPageOurProducts?.hero?.title}
          subtitle={data.sanityPageOurProducts?.hero?.subtitle}
          resume={data.sanityPageOurProducts?.hero?.text}
          photo={data.sanityPageOurProducts?.hero?.photo as Photo}
        />
        <SliderProducts />
        <GalleryProducts />
        <BenefitsAndInfo
          info={data.sanityPageOurProducts?.benefitsAndInfo?.info as Info}
          benefits={data.sanityPageOurProducts?.benefitsAndInfo?.benefits as Benefits}
        />
        <ServicesResume servicesList={serviceResumeList} />
        <WeKnowYourProblems
          title={data.sanityPageOurProducts?.weKnowYourProblems?.title}
          problemsList={data.sanityPageOurProducts?.weKnowYourProblems?.PAQsList as Problem[]}
        />
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default Products;

export const Head = ({ data }: PageProps<Queries.ProductsQuery>) => (
  <>
    <SEO
      title={data.sanityPageOurProducts?.seo?.title}
      robots={data.sanityPageOurProducts?.seo?.seo_robots}
      description={data.sanityPageOurProducts?.seo?.description}
      canonical={`distribucion-comercial/${data.sanityPageOurProducts?.seo?.canonical}`}
      imageAbsolutePath={data.sanityPageOurProducts?.seo?.thumbnail?.asset?.url}
    />
    <ServiceMarkupSchema
      titleHero={data.sanityPageOurProducts?.hero?.title}
      descriptionService={data.sanityPageOurProducts?.seo?.description}
      absolutePathImageOG={data.sanityPageOurProducts?.seo?.thumbnail?.asset?.url}
      absolutePathService={`
        ${data.sanityDocumentData?.urlSite}${data.sanityPageOurProducts?.slug?.current}
      `}
      problemList={data.sanityPageOurProducts?.weKnowYourProblems?.PAQsList as Problem[]}
    />
  </>
);

export const query = graphql`
  query Products {
    sanityDocumentData {
      urlSite
    }
    sanityPageOurProducts {
      slug {
        current
      }
      seo {
        title
        seo_robots
        description
        canonical
        thumbnail {
          asset {
            url
          }
        }
      }
      hero {
        title
        photo {
          alt
          image {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
        text: _rawText
        subtitle
      }
      weKnowYourProblems {
        title
        PAQsList {
          question
          answer: _rawAnswer
        }
      }
      CategoriesList {
        photo {
          alt
          image {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
        title
        resume: _rawResume
      }
      benefitsAndInfo {
        benefits {
          title
          benefitsList {
            text: _rawBlock
          }
        }
        info {
          title
          photo {
            alt
            image {
              asset {
                gatsbyImageData
                _id
              }
            }
          }
          text: _rawText
        }
      }
    }
    sanityPageApp {
      seo {
        thumbnail {
          asset {
            gatsbyImageData
            _id
          }
        }
      }
    }
    allSanityTemplateFoodServicesServices {
      nodes {
        slug {
          current
        }
        hero {
          title
        }
        seo {
          thumbnail {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
      }
    }
  }
`;
