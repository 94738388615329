import { Script, ScriptStrategy } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

import { toPlainText } from "../../utils/blocktext";
import { Problem } from "../WeKnowYourProblems/ProblemCard/ProblemCard";

type PropsServiceMarkupSchema = PropsWithChildren<{
  titleHero: string | null | undefined;
  descriptionService: string | null | undefined;
  absolutePathImageOG: string | null | undefined;
  absolutePathService: string | null | undefined;
  problemList: Problem[];
}>;
const ServiceMarkupSchema: React.FC<PropsServiceMarkupSchema> = (props: PropsServiceMarkupSchema) => {
  const markup = `{ 
        "@context": "https://schema.org/",
        "@type": "Service",
        "name": "${props.titleHero}",
        "description": "${props.descriptionService}",
        "image": "${props.absolutePathImageOG}",
        "url": "${props.absolutePathService}",
        "category": {
          "@type": "FAQPage",
          "mainEntity": [
          ${props.problemList.map((problem) => {
            return `{
                      "@type": "Question",
                      "name": "${problem.question}",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "${toPlainText(problem.answer)}"
                      }
                    }`;
          })}
        ]
      },
        "provider": {
        "@type": "Corporation",
        "name": "Grupo Usieto",
        "alternateName": "D.I.R.E USIETO, S.A.",
        "url": "https://www.grupousieto.com",
        "logo": "https://www.grupousieto.com/static/logo.webp",
        "location": {
          "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Zaragoza",
            "addressRegion": "ESP",
            "postalCode": "50197",
            "streetAddress": "Polígono Plaza C/ PERTUSA"}
            },
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "976 470 287",
          "contactType": "customer service",
          "contactOption": "TollFree",
          "areaServed": "ES",
          "availableLanguage": "es"
        },
        "sameAs": [
          "https://www.linkedin.com/company/grupo-usieto-log%C3%ADstica-y-distribuci%C3%B3n-comercial/",
          "https://www.youtube.com/channel/UCDvm-f5nNKSlYyyTqqna4gA",
          "https://www.grupousieto.com/contacto/"
        ]
      }
    }
  }
  `;
  return (
    <>
      <Script
        strategy={ScriptStrategy.postHydrate}
        type="application/ld+json">
        {markup}
      </Script>
    </>
  );
};

export default ServiceMarkupSchema;
