import "./slider-products.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { useRef } from "react";

import { left_svg, right_svg } from "../../assets/svg/controls_elements";
import DoNotMountComponent from "../DoNotMountComponent/DoNotMountComponent";
import useSlider, { AlignItem, MoveBy } from "../Slider/useSlider";
import ProductCard, { ProductCardType } from "./ProductCard/ProductCard";

const SliderProducts: React.FC = () => {
  const { sanityPageOurProducts: data }: Queries.sliderProductsQuery = useStaticQuery(graphql`
    query sliderProducts {
      sanityPageOurProducts {
        CategoriesList {
          photo {
            image {
              asset {
                gatsbyImageData
                _id
              }
            }
            alt
          }
          title
          resume: _rawResume
        }
      }
    }
  `);
  if (!data?.CategoriesList || data.CategoriesList.length === 0) {
    return <DoNotMountComponent sms={"No hay categorías"} />;
  }

  const sliderProducts = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { next, prev, isCanNext, isCanPrev, totalsPositions, currentPosition, onClickCard } = useSlider(
    sliderProducts,
    MoveBy.ITEMS
  );

  return (
    <section className={"o-slider-products"}>
      <div
        ref={sliderProducts}
        className={"c-slider-products"}>
        {data.CategoriesList.map((category, index) => {
          return (
            <ProductCard
              key={index}
              productCard={category as ProductCardType}
            />
          );
        })}
      </div>
      <div className="c-slider-products__controls">
        <div className="m-slider-products__controls">
          <button
            onClick={prev}
            disabled={!isCanPrev}
            aria-label={"desplazar a la izquierda el carrusel de productos"}
            className={`m-control m-control--outline-sea`}>
            {left_svg}
          </button>
          <button
            onClick={next}
            disabled={!isCanNext}
            aria-label={"desplazar a la derecha el carrusel de productos"}
            className={"m-control m-control--outline-sea"}>
            {right_svg}
          </button>
        </div>
      </div>
    </section>
  );
};

export default SliderProducts;
