import "./product-card.scss";

import BlockContent from "@sanity/block-content-to-react";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren } from "react";

import { Photo } from "../../../types/shared";

export type ProductCardType = {
  photo: Photo;
  title: string;
  resume: Record<string, unknown>;
};

type PropsProductCard = PropsWithChildren<{ productCard: ProductCardType }>;

const ProductCard: React.FC<PropsProductCard> = (props: PropsProductCard) => {
  return (
    <div className={"m-product-card"}>
      <div className={"m-product-card__content"}>
        <h3>{props.productCard.title}</h3>
        <BlockContent blocks={props.productCard.resume} />
      </div>
      <SanityImage
        {...props.productCard.photo.image}
        alt={props.productCard.photo.alt}
        className={"a-product-card__photo"}
        style={{ maxWidth: "382px" }}
        width={382}
        height={204}
      />
    </div>
  );
};

export default ProductCard;
