import "./gallery-products.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";

import Gallery from "../Gallery/Gallery";
import { Photo } from "../../types/shared";

const GalleryProducts: React.FC = () => {
  const { sanityPageOurProducts: data } = useStaticQuery(graphql`
    query galleryProducts {
      sanityPageOurProducts {
        gallery {
          alt
          image {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <section className={"o-gallery-products"}>
        <div className={"c-gallery-products"}>
          <h2>Galleria de productos</h2>
        </div>
      </section>
      <Gallery photosList={data?.gallery as Photo[]} />
    </>
  );
};

export default GalleryProducts;
