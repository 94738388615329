import "./benefits-n-info.scss";

import BlockContent from "@sanity/block-content-to-react";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren } from "react";

import { right_svg } from "../../assets/svg/controls_elements";
import { Photo } from "../../types/shared";
import DoNotMountComponent from "../DoNotMountComponent/DoNotMountComponent";
import ModalFormInfo from "../ModalFormInfo/ModalFormInfo";

export type Info = {
  title: string;
  photo: Photo;
  text: Record<string, unknown>;
};

export type Benefits = {
  title: string;
  benefitsList: {
    text: Record<string, unknown>;
  }[];
};

type PropsBenefitsAndInfo = PropsWithChildren<{
  info: Info | null | undefined;
  benefits: Benefits | null | undefined;
}>;

const BenefitsAndInfo: React.FC<PropsBenefitsAndInfo> = (props: PropsBenefitsAndInfo) => {
  if (!props.info || !props.benefits || props.benefits.benefitsList.length === 0)
    return <DoNotMountComponent sms={"No se puede montar el modulo de Beneficios e info"} />;

  const { ModalForm, toggleModal } = ModalFormInfo();

  return (
    <>
      <ModalForm />
      <section className={"o-benefits-n-info"}>
        <div className={"c-benefits-n-info"}>
          <div className="m-benefits-n-info__benefits">
            <h2 className="m-benefits-n-info__title">{props.benefits.title}</h2>
            {props.benefits.benefitsList.map((block, index) => {
              return (
                <div
                  key={index}
                  className={"m-benefits-n-info__benefit"}>
                  <BlockContent blocks={block.text} />
                </div>
              );
            })}
          </div>
          <div className="m-benefits-n-info__info">
            <div className="m-benefits-n-info__card">
              <SanityImage
                {...props.info.photo.image}
                alt={props.info.photo.alt}
                className={"a-benefits-n-info__photo"}
                style={{ maxWidth: "487px" }}
                width={487}
                height={324}
              />
              <p className={"a-benefits-n-info__card-title"}>{props.info.title}</p>
              <BlockContent blocks={props.info.text} />
              <button
                onClick={() => {
                  toggleModal();
                }}
                className={"m-button m-button--light m-button--large m-benefits-n-info__button-card"}>
                Contactar {right_svg}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BenefitsAndInfo;
