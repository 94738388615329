import "./services-resume.scss";

import * as React from "react";
import { PropsWithChildren, useLayoutEffect, useRef, useState } from "react";

import { left_svg, right_svg } from "../../assets/svg/controls_elements";
import { Photo, Slug } from "../../types/shared";
import { isThisElementFixInScreen } from "../../utils/window";
import DoNotMountComponent from "../DoNotMountComponent/DoNotMountComponent";
import useSlider, { AlignItem, MoveBy } from "../Slider/useSlider";
import ServiceCard from "./ServiceCard/ServiceCard";

export type ServiceResume = {
  photo: Photo;
  name: string;
  slug: Slug;
};

type PropsServicesResume = PropsWithChildren<{ servicesList: ServiceResume[]; is_firstBlue?: boolean }>;

const ServicesResume: React.FC<PropsServicesResume> = (props: PropsServicesResume) => {
  if (!props.servicesList || props.servicesList.length === 0)
    return <DoNotMountComponent sms={"Card de resumen el servicio vacía"} />;
  const sliderResumenCards = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [is_fixInScreen, setIsFixInScreen] = useState<boolean>(false);
  const { next, prev, isCanNext, isCanPrev, totalsPositions, currentPosition } = useSlider(
    sliderResumenCards,
    MoveBy.ITEMS
  );

  function calculateIsFixElementInScreen() {
    setIsFixInScreen(isThisElementFixInScreen(sliderResumenCards.current));
  }

  useLayoutEffect(() => {
    calculateIsFixElementInScreen();
    window.addEventListener("resize", calculateIsFixElementInScreen);
    return () => {
      window.removeEventListener("resize", calculateIsFixElementInScreen);
    };
  }, []);

  return (
    <section className={`o-services-resume ${props.is_firstBlue ? "o-services-resume--first-blue" : ""}`}>
      <div
        ref={sliderResumenCards}
        className={`${is_fixInScreen ? "c-services-resume" : "cs-services-resume"}`}>
        {props.servicesList.map((resume, index) => {
          return (
            <ServiceCard
              key={index}
              resume={resume}
            />
          );
        })}
      </div>
      {!is_fixInScreen && (
        <div className="c-services-resume c-services-resume--controls ">
          <div className="m-services-resume__c-controls">
            <button
              onClick={prev}
              disabled={!isCanPrev}
              aria-label={"desplazar a la izquierda el carrusel de subservicios"}
              className={`m-control`}>
              {left_svg}
            </button>
            <button
              onClick={next}
              disabled={!isCanNext}
              aria-label={"desplazar a la derecha el carrusel de subservicios"}
              className={"m-control"}>
              {right_svg}
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default ServicesResume;
